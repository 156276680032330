/* COMMON STYLES */

p {
  margin: 0!important;
  font-family: "Montserrat", sans-serif!important;
}

body {
  color: #0e0a40;
  width: 100vw !important;
  height: 100vh !important;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.main-actions {
  margin-bottom: 40px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-25 {
  margin-bottom: 25px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.full-width {
  width: 100%;
}

.justify-right {
  justify-content: right;
}

.push-margin-right {
  margin: 0 0 0 auto;
}

.relative {
  position: relative;
}

.footer-btns-container {
  margin-bottom: 100px;
}

.columns_settings {
  width: 100%;
  display: flex;
  grid-template-columns: 70fr 30fr;
}

.align_form {
  margin-top: 32px;
}

.profile_grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.textarea {
  font-family: "Montserrat", sans-serif;
  -webkit-appearance: none;
  margin-top: 5px;
  height: auto;
  border: 1px solid rgba(14, 10, 64, 0.15);
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 15px;
  font-size: 0.8rem;
  font-weight: 500;
  padding-top: 5px;
  color: #37454d;
}

@media (max-width: 600px) {
  .profile_grid {
    grid-template-columns: 1fr;
  }
}

.test {
  width: 100%;
  height: 30px;
  background-color: #3a91f7;
}

.crumb {
  font-size: 12px;
  color: #817ea5;
  font-weight: 600;
  cursor: pointer;
}
.crumb_arrow {
  font-size: 12px;
  color: #817ea5;
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
}
@media (max-width: 600px) {
  .crumb {
    font-size: 12px;
  }
  .crumb_arrow {
    font-size: 12px;
  }
  .crumb_current {
    font-size: 12px;
  }
}
.crumb_current {
  font-size: 12px;
  color: #0e0a40;
  font-weight: 600;
  cursor: not-allowed;
}

.left_edge {
  width: 15vw;
}

.row {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.space-between-no-center {
  display: flex;
  justify-content: space-between;
}

.flex-end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.flex-end-campaign{
  width: 98%;
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
}

.flex-end-center {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

h2 {
  font-size: 30px;
  color: #0e0a40;
  font-weight: 800;
}

strong {
  padding-right: 7px;
}

.login_logo {
  position: absolute;
  left: 20px;
  top: 20px;
}

.main_container {
  overflow-y: scroll;
  height: calc(100vh - 100px);
}

/* Buttons */
.primary {
  background-color: #fd552b;
  color: #ffffff;
  font-size: 0.9rem;
}

.login_btn {
  background: rgb(253, 85, 43);
  background: -moz-linear-gradient(
    270deg,
    rgba(253, 85, 43, 1) 0%,
    rgba(253, 85, 43, 1) 74%,
    rgba(253, 43, 124, 1) 100%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgba(253, 85, 43, 1) 0%,
    rgba(253, 85, 43, 1) 74%,
    rgba(253, 43, 124, 1) 100%
  );
  background: linear-gradient(
    270deg,
    rgba(253, 85, 43, 1) 0%,
    rgba(253, 85, 43, 1) 74%,
    rgba(253, 43, 124, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fd552b",endColorstr="#fd2b7c",GradientType=1);
  color: #ffffff;
}

.primary:hover {
  background: #c64422;
}

.secondary {
  background-color: #edeef4;
  color: #000000;
  font-weight: 600;
}

.secondary:hover {
  background: #dddddd;
}

.tertiary {
  background-color: #9facc7;
  color: #ffffff;
}

.outlined {
  background-color: white;
  color: rgba(63, 113, 236, 0.6);
  font-weight: 600!important;
  border: 1px solid rgba(63, 113, 236, 0.6)!important;
}

.success {
  background-color: #2bb992;
  color: #ffffff;
}

.white {
  background-color: #ffffff;
}

.btn_lg {
  height: 41px !important;
}

.with_tooltip:hover + .tooltip_black {
  display: block;
}

.tooltip_black {
  display: none;
  margin-top: 0.5rem;
  background-color: rgba(0, 0, 0, 0.35);
  color: white;
  padding: 7px;
  border-radius: 5px;
  font-family: Montserrat;
  font-size: 12px;
}

.onboarding_input_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  position: relative;
}

.onboarding_btn {
  position: absolute;
  right: -50px;
  top: 41px;
}

.onboarding_branch_grid {
  display: grid;
  grid-template-columns: 0.8fr 0.8fr 0.8fr 1.6fr;
  grid-gap: 10px;
  position: relative;
}

.onboarding_link {
  cursor: pointer;
  font-size: 14px;
  color: #8f9bb3;
  margin-right: 1rem;
}

.onboarding_save_btn {
  min-width: 66px;
  padding-left: 7px;
  padding-right: 7px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: rgba(143, 155, 179, 0.12);
  border: 1px solid #9da9c4;
  border-radius: 5px;
  color: #9da9c4;
  margin-right: 1rem;
}

.onboarding_save_btn:hover {
  background-color: rgba(143, 155, 179, 0.3);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.chart_blocker {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 9999;
}

.input_number_box {
  position: relative;
}

.arrow_up {
  width: 25px;
  height: 23px;
  background-color: rgba(228, 233, 242, 0.77);
  position: absolute;
  right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1px;
  cursor: pointer;
  border-radius: 0px 5px 0 0px;
}

.arrow_bottom {
  width: 25px;
  height: 23px;
  background-color: rgba(228, 233, 242, 0.77);
  position: absolute;
  right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 26px;
  cursor: pointer;
  border-radius: 0px 0px 5px 0px;
}

.arrow_bottom > img {
  transform: rotateZ(-180deg);
}

/* Text classes */
.rem-08 {
  font-size: 0.8rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.employee_header_row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.employee_header_export_row {
}

.min-w {
  width: min-content;
}

.dashboard_box_padding {
  width: 100%;
  height: 100%;
  padding: 29px 42px 36px 25px;
  position: relative;
}
@media (max-width: 600px) {
  .dashboard_box_padding {
    padding: 16px;
  }
}

.select-react div.control {
  height: 50px;
  border: 1px solid #cde1eb;
}

.line {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #e3ecff;
  margin-top: 10px;
  margin-bottom: 35px;
}

.line_element {
  position: absolute;
  left: 0;
  top: -1px;
  width: 40px;
  height: 3px;
  background-color: #e0e9f5;
}

.table_line {
  margin-bottom: 27px;
}

.table_row {
  font-family: Montserrat;
  width: 100%;
  height: 74px;
  align-items: center;
  border: 1px solid #f3f3f3;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  margin-bottom: 4px;
  color: #000000;
}

.standard-padding-l {
  padding-left: 40px;
}

.standard-margin-l {
  margin-left: 40px;
}

/* SEARCH BOX */
.search_container {
  min-width: 450px;
  border-radius: 11px 10px 10px 11px;
  display: flex;
}

@media (max-width: 900px) {
  .search_container {
    width: 100%;
    min-width: 200px;
    border-radius: 11px 10px 10px 11px;
    display: flex;
  }
}
@media (max-width: 700px) {
  .header_orders {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.dark_search {
  height: 45px;
  background-color: #edeef4;
}
.light_search {
  height: 47px;
  background-color: #ffffff;
  border: 1px #e3e5f1 solid;
}
.search_container input::placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: rgba(124, 130, 138, 0.38);
}
.search_button {
  height: 45px;
  width: 50px;
  background-color: rgba(81, 93, 167, 0.16);
  border-radius: 10px;
  cursor: pointer;
}
.search_container input {
  width: 100%;
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding-left: 15px;
  color: rgba(124, 130, 138, 1);
}
.header_search_responsive_show {
  display: none;
}
.header_search_responsive_hide {
  display: none;
}
.search_icon {
  width: 18px;
  height: 18px;
}

/* UPLOAD IMAGE COMPONENT */

.image_upload {
  position: relative;
  width: 262px;
  height: 262px;
  border-radius: 50%;
  border: 2px solid #dee1e7;
  background-size: cover;
  background-position: center;
}

.upload_image {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
}

.upload_user {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
}

.hide-file-input {
  position: absolute;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  right: 0;
  z-index: 2;
  opacity: 0;
}
.custom_file_input {
  position: absolute;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: #9facc7;
  right: 0;
  z-index: 1;
}

.custom_file_input > img {
  width: 25px;
}

.side_product_title {
  font-size: 16px;
  padding-left: 15px;
  font-weight: 700;
}

.plans_wrapper {
  width: 100%;
  max-width: 1400px;
}

.invoice-status {
  color: #bbbac9;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 2px solid #e0e0e7;
  padding-bottom: 10px;
}

.invoice_image {
  width: 90%;
}

.invoice-header {
  color: #001940;
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e7;
  padding-bottom: 10px;
}

.invoice-data {
  color: #001940;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}

.invoice-amount {
  color: #767493;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  line-height: 37px;
}
.csv_reader {
  position: absolute;
  left: 0;
  top: 0;
}

.csv_label {
  display: none;
}

.csv_upload_wrapper {
  background-color: #f0b619;
  width: 100%;
  height: 40px;
  opacity: 0;
}

.csv_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* COLORS */
.red {
  background-color: red;
}
.green {
  background-color: #00c18d;
}

/* SIZES */
.pc80 {
  width: 80%;
}

/* FONTS */
h4 {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
  color: #292452;
}

/* SHADOW */
.shadow {
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}

/* TOASTS */
.Toastify__toast--success {
  background: rgb(222, 242, 214);
  color: rgb(130, 105, 56);
}
.Toastify__toast--warning {
  background: rgb(248, 243, 215);
  color: rgb(140, 57, 58);
}
.Toastify__toast--error {
  background: rgb(235, 200, 196);
  color: rgb(90, 110, 82);
}
.Toastify__close-button > svg {
  fill: rgb(140, 57, 58);
}

@media (max-width: 800px) {
  .pending_box {
    max-width: 87vw;
  }
}

.companies_sub_title {
  text-align: center;
  margin-top: 0.5rem;
  font-size: 14px;
  color: #37454d;
}

.companies_table_container {
  margin-top: 3rem;
  background-color: white;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
}
.companies_table_container > img {
  width: 300px;
  max-width: 50vw;
}
.companies_table_container > h3 {
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 600;
}
.companies_table_container > p {
  margin-top: 0.5rem;
  font-size: 14px;
  font-weight: 500;
  color: #37454d;
}
.oboarding_container {
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
}
.onboarding_sub_title {
  font-size: 11px;
  color: #333333;
}
.space-between_onboarding {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(143, 155, 179, 0.26);
  padding-bottom: 0.5rem;
}

/*.settings_tab {
    margin-top: 30px;
}*/

.perm_table_box {
  background-color: white;
  padding-bottom: 150px;
}

.sub_title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}
@media (max-width: 700px) {
  .sub_title {
    max-width: 40vw;
    margin-bottom: 1rem;
    font-size: 12px;
  }
}

.space-between_plans {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 450px) {
  .space-between_plans {
    display: block;
  }
}

.employee_select_row {
  display: flex;
  align-items: center;
}
@media (max-width: 600px) {
  .employee_select_row {
    display: block;
  }
}

.form_radio input[type="radio"] {
  display: none;
}
.form_radio label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 0;
  line-height: 18px;
  user-select: none;
  font-size: 14px;
}
.form_radio label:before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 0;
  bottom: 1px;
  border-radius: 50%;
  border: 2px solid #767493;
}

.customReactSelectMenu {
  background-color: white;
}

.permission_select_btn_box {
  width: 100%;
  padding: 10px;
}

/* Checked */
.form_radio input[type="radio"]:checked + label:before {
  background: radial-gradient(#767493 50%, rgba(255, 0, 0, 0) 51%);
}

/* Hover */
.form_radio label:hover:before {
  filter: brightness(120%);
}

/* Disabled */
.form_radio input[type="radio"]:disabled + label:before {
  filter: grayscale(100%);
}

.cookie_container {
  padding-left: 20%;
  padding-right: 20%;
  min-height: 200px;
  color: white;
  background-color: #070948 !important;
  padding-top: 20px;
}

.cookie_title {
  font-size: 16px;
  font-weight: 600;
}

.cookie_text {
  font-size: 12px;
  margin-top: 1rem;
}

.cookie_link {
  color: #f67b44;
  text-decoration: none;
  font-weight: 600;
}

.cookie_button {
  position: absolute;
  bottom: 20px;
  background-color: #f67b44 !important;
  color: white !important;
  border-radius: 30px !important;
  font-weight: 600;
  padding: 10px 15px 10px 15px !important;
}

@media (max-width: 900px) {
  .cookie_button {
    position: relative;
  }
}

@media (max-width: 700px) {
  .cookie_container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.footer_link {
  position: absolute;
  bottom: 10px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  text-decoration: none;
  margin-left: 1rem;
}

@media (max-width: 700px) {
  html {
    font-size: 70%;
  }
}

.update_info_arrow {
  width: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.update_info_img {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: black;
  background-size: cover;
}

.update_info_img.small {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
}
