.invoice_status_box {
  position: relative;
  margin-right: 0.5rem;
}

.badge {
  width: max-content;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.new_badge {
  color: #f2a338;
  background-color: rgba(255, 236, 206, 0.8);
}

.rejected_badge {
  color: #e56a5f;
  background-color: rgba(255, 162, 157, 0.5);
}

.implementation_badge {
  background-color: rgba(0, 130, 182, 0.5);
  color: #fcfffd;
}

.done_badge {
  background-color: rgba(0, 182, 155, 0.25);
  color: #0d9683;
}

.badge.small  {
  width: max-content;
  padding: 2px 5px;
  border-radius: 2px;
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: 11px;
}
