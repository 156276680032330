.corporate_grid {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-gap: 40px;
}

.corporate_input_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.corporate_grid_3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.campaign_style_input {
  display: flex;
  justify-content: center;
}

.campaign_style_input >  {
width: 200px;
}
