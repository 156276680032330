h2 {
  margin-top: 11px;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 700;
}

.page-header p {
  font-size: 12px;
  font-weight: 300;
}
