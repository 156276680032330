input[type="checkbox"] {
  display: none;
}
.checkbox_group {
  display: flex;
  flex-direction: column;
}
.checkbox_unchecked + label::before {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 0.8px solid #e1e1e1;
  background-color: #fff;
  display: block;
  content: "";
  float: left;
  margin-right: 5px;
}
.checkbox_radio_unchecked + label::before {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #cde1eb;
  background-color: #fff;
  display: block;
  content: "";
  float: left;
  margin-right: 15px;
}
.radio_checked + label::before {
  width: 16px;
  height: 16px;
  border: 2px solid #9391a9;
  background-color: #b3b2c3;
  margin-right: 15px;
}
.checkbox_checked + label::before {
  width: 20px;
  height: 20px;
  border: 0.8px solid #9391a9;
  background-color: #b3b2c3;
  background-image: url("../../img/input-checkmark.svg");
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
}
.check-group_label > p {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
}
