.empty_table_container {
    width: 100%;
    height: 600px;
    background-color: white;
}
.empty_table_box > h2 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.empty_table_box > p {
    text-align: center;
    max-width: 400px;
    line-height: 1.5rem;
}
