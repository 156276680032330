.sidebar_container {
  height: calc(100vh - 100px);
  background-color: #0e0a40;
  padding-top: 30px;
}
.hide_sidebar {
  width: 95px;
  height: calc(100vh - 100px);
  background-color: #0e0a40;
  padding-top: 30px;
}

@media (max-width: 1000px) {
  .hide_sidebar {
    display: none;
  }
}

.sidebar_text_menu {
  font-size: 14px;
  color: #767493;
  cursor: pointer;
  height: 40px;
  font-weight: 600;
  padding-left: 10px;
}

.sidebar_text_menu:hover {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    95deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.17) 13%,
    rgba(255, 255, 255, 0.14) 28%,
    rgba(255, 255, 255, 0.1) 44%,
    rgba(255, 255, 255, 0.06) 67%,
    rgba(255, 255, 255, 0.02) 100%
  );
  border-left: 3px solid #f35252;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.sidebar_tab {
  font-family: Montserrat;
  width: 100%;
  height: 50px;
  cursor: pointer;
  transition: 0.2s;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #767493;
}
.sidebar_tab:hover {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    95deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.17) 13%,
    rgba(255, 255, 255, 0.14) 28%,
    rgba(255, 255, 255, 0.1) 44%,
    rgba(255, 255, 255, 0.06) 67%,
    rgba(255, 255, 255, 0.02) 100%
  );
  border-left: 3px solid #f35252;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.sidebar_tab:hover #dashboard_icon {
  filter: brightness(3) contrast(3) saturate(1.4);
}

.selected_tab {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    95deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.17) 13%,
    rgba(255, 255, 255, 0.14) 28%,
    rgba(255, 255, 255, 0.1) 44%,
    rgba(255, 255, 255, 0.06) 67%,
    rgba(255, 255, 255, 0.02) 100%
  );
  border-left: 3px solid #f35252;
  color: white;
  font-size: 14px;
}

.selected_tab #dashboard_icon {
  filter: brightness(3) contrast(3) saturate(1.4);
}

.sidebar_text {
  padding-left: 10px;
  font-weight: 100;
}

@media (max-width: 1300px) {
  .sidebar_container {
    position: absolute;
    z-index: 2;
  }
}
