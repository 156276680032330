.login-page {
  width: 100vw;
  height: 100vh;
}

.login-main-container {
  background-image: url("../../img/login_bg.png");
  background-color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
}

.login_block {
  width: 100%;
  max-width: 950px;
}
.login_block > h1 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: white;
}
.login_block > h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin-bottom: 3rem;
}
.login-image {
  position: absolute;
  max-height: 450px;
  left: 1%;
  bottom: 5%;
}

.login-title {
  font-size: 30px;
}

.login-text {
  font-size: 24px;
  font-weight: lighter;
}

.login-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 650px;
  height: 100%;
  min-height: 320px;
  width: 100%;
  border-radius: 20px;
  background: white;
  padding: 60px 60px 70px 60px;
  -webkit-box-shadow: 0 0 16px 7px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0 0 16px 7px rgba(0, 0, 0, 0.13);
  box-shadow: 0 0 16px 7px rgba(0, 0, 0, 0.13);
}

@media (max-width: 450px) {
  .login-container {
    border-radius: 10px;
  }
}

.login-form-title {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.remember-container {
  margin-top: 5px;
  margin-bottom: 25px;
}

.remember-text {
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
  margin-left: 5px;
  font-size: 12px;
}

.remember-text_normal {
  cursor: pointer;
  margin-left: 5px;
  font-size: 12px;
  font-weight: normal;
}

.login_btn_container {
  margin: 30px auto 20px auto;
  width: 85%;
}

.dots-container {
  width: 85%;
  margin-top: 15px;
  max-width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  margin-left: 5px;
  margin-right: 5px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #111111;
  opacity: 42%;
}

.selected-dot {
  width: 30px;
  border-radius: 15px;
}

.hide-container {
  position: relative;
}

.hide-toggle {
  position: absolute;
  right: 10px;
  bottom: 23px;
  cursor: pointer;
}

.left-carousel-container {
  height: 560px;
  width: 100%;
}

.welcome-container {
  display: none;
  width: 100vw;
  height: 100vh;
  padding-right: 10%;
}

.welcome-title {
  text-align: center;
  font-size: 43px;
  font-weight: lighter;
  margin-top: 10vh;
}

.welcome-image-container {
  margin-top: 10vh;
}

/* WELCOME IMAGE AFTER LOGIN */
.show-welcome {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: url("../../img/background-login.svg");
  background-color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 43px;
}

.welcome-login-image {
  margin-top: 70px;
  width: 40vw;
}

.hide-welcome {
  display: none;
}

.login-main-container-hide {
  display: none;
}

.left-carousel-container {
  display: none;
}
.forgot_title {
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 30px;
}
.input_error {
  font-size: 14px;
  text-align: right;
  margin-top: -15px;
  color: rgba(151, 57, 55, 0.7);
}
.input_warning {
  position: absolute;
  font-size: 10px;
  text-align: left;
  bottom: -1px;
  color: rgba(151, 57, 55, 0.7);
}

@media (max-width: 500px) {
  .input_warning {
    font-size: 8px;
  }
}

.forgot_line {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 1px;
  background-color: rgba(118, 116, 147, 0.34);
}

.forgot_back {
  text-align: center;
  font-size: 18px;
  color: #ff675a;
  margin-bottom: -30px;
  cursor: pointer;
}
@media (max-width: 450px) {
  .forgot_back {
    font-size: 14px;
  }
}
.forgot_success_text {
  font-size: 1rem;
  font-weight: 500;
}

.resend_counter {
  font-size: 1.2rem;
  font-weight: 600;
}

@media (max-width: 1300px) {
  .login_block {
    margin-top: 80px;
  }
  .login-main-container {
    background-attachment: scroll;
  }
}

@media (max-width: 900px) {
  .login_block {
    max-width: 90%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 700px) {
  .login-container {
    padding: 1rem;
  }
}
