/* STYLES FOR ALL TABLES */
.side_table_wrapper {
  width: 100%;
  max-width: 68vw;
  overflow-x: auto;
}
.table_box {
  width: 100%;
}
@media (max-width: 600px) {
  .table_box {
    width: 100%;
    overflow: auto;
  }
}
.table_wrapper {
  width: 100%;
  min-height: 100%;
}

.table_wrapper th {
  text-align: left;
  height: 75px;
  padding-left: 25px;
  background-color: #dfdfea;
  font-size: 12px;
  font-weight: 600;
  color: #222222;
}
th {
  text-align: left;
  padding: 0;
  font-size: 12px;
  background-color: #dfdfea;
  height: 76px;
}

.table_wrapper th:first-child {
  border-radius: 5px 0 0 5px;
}
.table_wrapper th:last-child {
  border-radius: 0 5px 5px 0;
}
.spacer {
  height: 4px;
}
.table-item {
  height: 74px;
  background-color: #ffffff;
}

.blocked_item {
  height: 74px;
  background-color: #eeeef3;
}

.blocked_item > td {
  color: #989898;
}

.selected_row {
  height: 74px;
  background-color: rgba(118, 116, 147, 0.22);
}

.permission_row {
  border-bottom: 1px solid rgba(12, 32, 53, 0.1);
}

.list-triangle {
  position: relative;
  z-index: 0;
  font-size: 22px;
  cursor: pointer;
  margin-left: 10px;
  color: #c3c4d1;
}
.list-triangle.top {
  transform: rotateZ(-180deg) translateY(-10px);
}
.list-triangle.bottom {
  transform: translateY(-10px);
}
.list-triangle.selected {
  color: #000435;
}
/* PAGE TABLES FOR EMPLOYEES */
table.page-table td {
  padding-left: 0px;
}
table.page-table tr td:first-child {
  padding-left: 10px;
}

table.perm_table tr td {
  border: solid 1px #f3f3f3;
  border-style: none none solid none;
  font-weight: 500;
  color: #000000;
  font-size: 12px;
}

/* ONLY ONE WAY TO DO BORDER-RADIUS */
table {
  border-collapse: separate;
  border-spacing: 0;
}
td {
  border: solid 1px #f3f3f3;
  border-style: none solid solid none;
  padding-left: 25px;
  font-weight: 500;
  color: #000000;
  font-size: 12px;
}

.selected_row {
  border: solid 1px rgba(116, 116, 147, 0.22);
  border-style: none solid solid none;
  padding-left: 25px;
  font-weight: 500;
  color: #000000;
  font-size: 12px;
}
.selected_row {
  border-top-style: solid;
}

tr td:first-child {
  border-bottom-left-radius: 5px;
}
tr td:last-child {
  border-bottom-right-radius: 5px;
}
tr td:first-child {
  border-top-left-radius: 5px;
}
tr td:last-child {
  border-top-right-radius: 5px;
}
tr td {
  border-top-style: solid;
}
tr td:first-child {
  border-left-style: solid;
}
tr td:not(:last-child) {
  border-right-style: hidden;
}

/* Employee without App STYLES */
.employee_dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

td a {
  text-decoration: none;
  color: black;
}

.employee_table {
  width: 100%;
}
@media (max-width: 700px) {
  .employee_table {
    table-layout: fixed;
  }
}
/* Pending orders STYLES */
.action_dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #a2adbf;
}
.action_container {
  cursor: pointer;
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}
.action_dots_container {
  position: relative;
}
.table_menu {
  position: absolute;
  min-width: 180px;
  min-height: 30px;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  right: 20px;
  top: 15px;
  z-index: 10 !important;
}
.table_menu_item {
  height: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 10px;
}
.table_menu_item > p {
  padding-left: 7px;
  font-size: 12px;
  font-weight: 600;
  color: #4b5960;
}
.table_menu_item > img {
  width: 17px;
  height: 17px;
}
.table_menu_item:not(:last-child) {
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
}

.table-status {
  margin-top: 26px;
  color: #747578;
  font-size: 14px;
}

.benefits_btn_box {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: end;
  padding: 5px;
}

.benefits_list_input {
  font-family: "Montserrat", sans-serif;
  -webkit-appearance: none;
  margin-top: 5px;
  height: 30px;
  border: 1px solid rgba(14, 10, 64, 0.15);
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #37454d;
}

@media (max-width: 1050px) {
  .table_box {
    width: 100%;
    max-width: 90vw;
    overflow-x: scroll;
  }
  .responsive-mr-40 {
    margin-right: 40px;
  }
}

.list_check_text_mail {
}
