.pagination-footer-container {
  margin-top: 5px;
  height: 50px;
}
.pagination-footer-info {
  height: 100%;
  width: 210px;
  font-size: 14px;
  color: #707070;
}
.pagination-footer-count {
  margin-left: 4px;
  margin-right: 4px;
}
.pagination-page {
  width: 43px;
  height: 30px;
  background-color: #fafbfd;
  cursor: pointer;
}
.pagination-page:first-child {
  border-radius: 8px 0 0 8px;
  border: 1px solid #d5d5d5;
}
.pagination-page:last-child {
  border-radius: 0 10px 10px 0;
  border: 1px solid #d5d5d5;
  border-left: none;
}
.pagination-page:last-child > img {
  transform: rotateZ(-180deg);
}

.change_limit_box {
  position: relative;
}

.change_limit_box > p {
  font-size: 14px;
  color: #707070;
  margin-right: 30px;
}

.change_limit_box > p > span {
  color: #00acee;
  font-weight: 600;
  text-decoration: underline;
}

.change_limit_menu {
  position: absolute;
  background-color: white;
  font-size: 12px;
  right: 25px;
  top: 20px;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}

.change_limit_item {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.change_limit_item:hover {
  background-color: rgba(112, 112, 112, 0.2);
}
