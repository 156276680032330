main {
  width: 100%;
  min-height: 100%;
  padding: 40px;
  background-color: #f2f2f4;
}

@media (max-width: 600px) {
  main {
    width: 100%;
    min-height: 100%;
    padding: 16px;
    background-color: #f2f2f4;
  }
}
