.header {
  position: relative;
  height: 100px;
  -webkit-box-shadow: 1px 2px 4px -1px rgba(0, 0, 0, 0.18);
  box-shadow: 1px 2px 4px -1px rgba(0, 0, 0, 0.18);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 52px;
  z-index: 9999;
}

.header_row {
  display: flex;
  align-items: center;
}

.user-container-header {
  position: relative;
  min-width: 200px;
  cursor: pointer;
}

.header_logo {
  height: 37px;
}

.user-image-header {
  width: 50px;
  height: 50px;
  background-size: cover;
  border-radius: 50%;
  margin-right: 20px;
}

.user-name-header {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
}

.user-arrow-show {
  display: flex;
  transition: 0.3s;
}

.user-arrow-hide {
  transform: rotateZ(180deg);
  transition: 0.3s;
}

.user-menu-header {
  background: #ffffff;
  min-width: 200px;
  position: absolute;
  right: 5%;
  top: -27%;
  transform: translateY(80px);
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 2;
}

.hide-menu {
  display: none;
}

.header-user-menu-item {
  width: 100%;
  height: 45px;
  padding-left: 15px;
  font-weight: 600;
  border-bottom: 1px solid #efecec;
  cursor: pointer;
}

.header-user-menu-item > p {
  font-size: 14px;
  font-weight: lighter;
}

.header-user-menu-item:hover p {
  font-size: 16px;
  transition: 0.3s;
}

div.header_search.search_container {
  margin-left: 15px;
}

/* BURGER MENU */

.burger_menu {
  width: 24px;
  height: 15px;
  margin-left: 30px;
  margin-right: 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: width 0.2s;
}

.burger_menu:hover {
  width: 30px;
}

.burger_line {
  width: 100%;
  height: 3px;
  background-color: #242424;
  transition: 0.3s;
}
.burger_line_close {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 3px;
  background-color: #242424;
  transition: 0.2s;
}

/* NOTIFICATION BUTTON */
.header_notification {
  height: 24px;
  width: 22px;
  margin-right: 36px;
  cursor: pointer;
  position: relative;
}
.notification_dot {
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 13px;
  position: absolute;
  right: -15px;
  top: -15px;
  background-color: #fe5f55;
}

@media (max-width: 1000px) {
  /* SHOW SEARCH */
  .header_search_responsive_show {
    width: 450px;
    max-width: 80vw;
    height: 45px;
    margin-left: 15px;
    border-radius: 10px;
    background-color: #edeef4;
    display: flex;
    transition: 0.3s;
  }
  .header_search_responsive_show > input {
    width: 100%;
    -webkit-appearance: none;
    border: none;
    background-color: transparent;
    font-size: 14px;
    padding-left: 15px;
    color: rgba(124, 130, 138, 1);
  }
  .header_search_responsive_show > input::placeholder {
    color: rgba(124, 130, 138, 0.38);
  }
  /* HIDE SEARCH */
  .header_search_responsive_hide {
    width: 45px;
    height: 45px;
    margin-left: 15px;
    border-radius: 10px;
    background-color: #edeef4;
    display: flex;
    transition: 0.3s;
  }
  .header_search_responsive_hide > input {
    display: none;
  }
  .header_row :first-child {
    order: 2;
  }
  .header_row :nth-child(2) {
    order: 1;
  }
  .header_row :last-child {
    order: 3;
  }
}

@media (max-width: 615px) {
  .user-name-header {
    display: none;
  }
  .user-container-header {
    min-width: 50px;
  }
  .burger_menu {
    margin-left: 20px;
    margin-right: 0;
  }
  .header_notification {
    margin-right: 25px;
  }
}

@media (max-width: 400px) {
  .header_search_responsive_show {
    max-width: 70vw;
  }
  .header_logo {
    display: none;
  }
}
