.associated_company_badge {
    margin-right: 15px;
    padding: 3px 7px;
    border-radius: 7px;
    background-color: #5fc9ee;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.associated_company_badge > p {
    margin-bottom: 0;
    font-size: 16px;
    padding-left: 10px;
    padding-bottom: 2px;
    cursor: pointer;
}
