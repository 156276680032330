.error_page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #ececef;
}
.error_page_box {
  position: relative;
  width: 70vw;
  padding-top: 30vh;
  margin-left: auto;
  margin-right: auto;
}
.error_type {
  position: absolute;
  left: 0;
  top: 20vh;
  font-size: 35vw;
  color: #e3e3e9;
}
.error_page_title {
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 3rem;
}
.error_page_text {
  padding-top: 2rem;
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}
.error_page_box > div {
  position: relative;
  z-index: 2;
  margin-top: 3rem;
}
.error_btn {
  max-width: 305px;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
}
