.btn {
  border-radius: 7px;
  height: 40px;
  border: 0;
  font-family: "Montserrat", "sans serif";
  font-size: 14px;
  font-weight: 500;
  max-width: 205px;
  width: 100%;
  cursor: pointer;
  letter-spacing: 0.1px;
}

.btn_full {
  border-radius: 7px;
  height: 40px;
  border: 0;
  font-family: "Montserrat", "sans serif";
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  margin-top: 30px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_icon {
  margin-right: 7px;
}

.add-person {
  max-width: 205px;
  margin-left: auto;
}

.btn_round {
  height: 38px;
  width: 38px;
  min-width: 38px;
  min-height: 38px;
  border: 0px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  vertical-align: bottom;
  box-shadow: 0 3px 6px rgba(178, 178, 178, 0.16);
  cursor: pointer;
}

.btn_round img {
  margin-top: 7px;
}

.h-39 {
  height: 39px;
  padding: 0;
}

button:disabled,
button[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
